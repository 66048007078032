import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchUserSettingsDP,
  updateUserSettingsDP,
} from "../../../utils/api/user-settings-dp.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import Popup from "../../Charts/Popup";
import { Drawer } from "../../common";
import CallsFilter from "./Calls";
import ContactDate from "./ContactDate";
import ContactEngagement from "./ContactEngagement";
import ContactProperties from "./ContactProperties";
import Events from "./Events";
import RestrictedContacts from "./RestrictedContacts";
import {
  SearchAndFilterForm,
  defaultFilterValues,
  hasFilterSelectedWithAssociatedDate,
  searchAndFilterValidation,
} from "./utils";

type SearchAndFilterDrawerProps = {
  onClose: () => void;
  isOpen: boolean;
  pageReset?: () => void;
  setFilters?: (filters: SearchAndFilterForm["filter_and_sort"]) => void;
  filters?: SearchAndFilterForm["filter_and_sort"];
  screenName?: string;
  dealership_id?: string;
};

const SearchAndFilterDrawer = ({
  isOpen,
  onClose,
  pageReset,
  setFilters,
  filters,
  screenName,
  dealership_id,
}: SearchAndFilterDrawerProps) => {
  const user = useUserInfo("user");
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const { control, setValue, reset, handleSubmit } =
    useForm<SearchAndFilterForm>({
      defaultValues: defaultFilterValues,
      resolver: yupResolver(searchAndFilterValidation) as any,
      mode: "all",
    });

  const location = useLocation();
  const toast = useToast();
  const navigate = useNavigate();

  // tag that we select from the contacts option from tags menu
  const tag = localStorage.getItem("tag")
    ? JSON.parse(localStorage.getItem("tag")!)
    : "";

  const handleFormSet = useCallback(async () => {
    if (filters) return reset({ filter_and_sort: filters });
    const searchAndFilters = JSON.parse(
      localStorage.getItem("search_and_filter") || "null"
    );

    if (searchAndFilters) {
      const tags = searchAndFilters?.contact_attributes?.tags;
      const isAlreadyInTags = tags?.some((t: any) => t.value === tag?.value);
      let updatedTags = tags;

      if (!isAlreadyInTags) {
        updatedTags = [...(tags || []), tag].filter((value) => !!value);
      }

      localStorage.removeItem("tag");

      reset({
        filter_and_sort: {
          ...searchAndFilters,
          date_range: {
            start_date: searchAndFilters.date_range.start_date
              ? new Date(searchAndFilters.date_range.start_date)
              : "",
            end_date: searchAndFilters.date_range.end_date
              ? new Date(searchAndFilters.date_range.end_date)
              : "",
          },
          contact_attributes: {
            ...searchAndFilters.contact_attributes,
            created_at: searchAndFilters.contact_attributes.created_at
              ? new Date(searchAndFilters.contact_attributes.created_at)
              : "",
            tags: updatedTags || [],
          },
        },
      });
    }
  }, [reset]);

  useEffect(() => {
    handleFormSet();
  }, [handleFormSet]);

  const handleFormSubmit = (values: SearchAndFilterForm) => {
    if(values.filter_and_sort.association_date && !hasFilterSelectedWithAssociatedDate(values)){
      toast({
        title: "Please select a filter for association date",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    
    if (setFilters) {
      setFilters(values.filter_and_sort);
      return onClose();
    }
    if (pageReset) {
      pageReset();
    }
    localStorage.setItem(
      "search_and_filter",
      JSON.stringify(values.filter_and_sort)
    );
    location.pathname !== "/leads/lead-details" &&
      navigate("/leads/lead-details");
    onClose();
  };

  //.............focus mode check.............
  const {
    data,
    isFetching: isLoadingFocusMode,
    refetch,
  } = useQuery({
    queryKey: ["focusMode", user],
    queryFn: () =>
      fetchUserSettingsDP(
        user?.dealership?.id,
        user?.id,
        "visibility_settings"
      ),
    refetchOnWindowFocus: false,
    retry: false,
  });
  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) =>
      await updateUserSettingsDP(payload, user?.dealership?.id, user?.id),
    onError: (error: any) => {
      toast({
        description: `Error Updating focus mode: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} title="Filter & Sort">
        {/* {(isLoading) && <Loader/>} */}
        <Box>
          <ContactDate control={control} setValue={setValue} />
          <ContactProperties
            control={control}
            setValue={setValue}
            dealership_id={dealership_id}
          />
          <Events control={control} setValue={setValue} />
          <CallsFilter control={control} setValue={setValue} />
          <ContactEngagement control={control} setValue={setValue} />
          <RestrictedContacts control={control} setValue={setValue} />

          <DrawerFooter
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            borderTop="1px solid var(--grey-300)"
            display="flex"
            alignItems="center"
            gap="0.81rem"
            background="white"
          >
            <Button
              variant="outline"
              w="100%"
              size="xl"
              onClick={() => {
                reset(defaultFilterValues);
                if (setFilters) {
                  return pageReset && pageReset();
                }
                if (pageReset) {
                  pageReset();
                }
                localStorage.removeItem("search_and_filter");
              }}
            >
              Reset Filters
            </Button>
            <Button
              w="100%"
              size="xl"
              onClick={() => {
                if (
                  screenName === "LeadDetailsPage" &&
                  data?.settings_hash?.focus_mode
                ) {
                  setIsOpenPopup(true);
                } else {
                  handleSubmit((values) => handleFormSubmit(values))();
                }
              }}
            >
              Apply
            </Button>
          </DrawerFooter>
        </Box>
      </Drawer>
      {screenName === "LeadDetailsPage" && isOpenPopup && (
        <Popup
          isOpen={true}
          onClose={() => setIsOpenPopup(false)}
          onConfirm={() => {
            const requestData = {
              key: "visibility_settings",
              settings_hash: {
                focus_mode: !data?.settings_hash?.focus_mode,
              },
            };

            mutation.mutate(requestData);
            handleSubmit((values) => handleFormSubmit(values))();
            setIsOpenPopup(false);
          }}
          title="Focus Mode Confirmation"
          message="If you apply filters then the focus mode will be turned off. Are you sure you want to apply them?"
        />
      )}
    </>
  );
};

export default SearchAndFilterDrawer;
